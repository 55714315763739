import React, { useState,useRef,useEffect } from 'react';
import { useInboxContext } from './InboxContext';
import { FiX, FiChevronDown, FiCheck, FiSearch } from 'react-icons/fi';
import { BiCheckDouble, BiCheck  } from "react-icons/bi";
import { ImUserCheck } from "react-icons/im";
import Picker from 'emoji-picker-react';
import { send_message,fetch_chat,check_status } from '../../../services/whatsapp/Service';
import TemplatePopup from '../Inbox/TemplatePopup';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const ChatArea = () => {
  const { initialMessageCount,setInitialMessageCount,selectedContact,resetSelectedContact,showkeyboard,chatResult,loadingMore,setLoadingMore,chatPage,setChatResult,setChatpage,setCurrentlySelectedContact} = useInboxContext();
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
  const [searchAssignee, setSearchAssignee] = useState('');
  const [activeTab, setActiveTab] = useState('reply');
  const [showTemplatePopup, setShowTemplatePopup] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [noteText, setNoteText] = useState('');
  const [fileType, setFileType] = useState('');
  const [msgType, setMsgType] = useState('');
  const [isScroll, setisScroll] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);
  const previousMessageCountRef = useRef(initialMessageCount);
  const id = selectedContact && selectedContact[0] ? selectedContact[0].userId : null;


  const [textMessage, setTextMessage] = useState('');       // For sending normal text
  const [imageFile, setImageFile] = useState(null);         // Selected image file
  const [imagePreview, setImagePreview] = useState(null);   // Image preview URL
  const [imageNote, setImageNote] = useState('');           // Note for image with text
  const [isPopupOpen, setIsPopupOpen] = useState(false);    // Toggle for image preview popup
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null); // Ref for chat container
  const chatEndRef = useRef(null);
  const previousScrollHeightRef = useRef(0);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const [newMessageCount, setNewMessageCount] = useState(0); // New message count


  const [sendText, setSendText] = useState(false); // New loading state

  const [activeTooltip, setActiveTooltip] = useState(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [showNewMessageAlert, setShowNewMessageAlert] = useState(false); // Track initial message count

  useEffect(() => {
    // Fetch initial message count if it hasn’t been set yet
    const fetchInitialMessageCount = async () => {
      try {
        const response = await check_status({ id });
        const initialCount = response.data.data.msgCount;
        setInitialMessageCount(initialCount);
        previousMessageCountRef.current = initialCount; // Set the ref to this initial value
      } catch (error) {
        console.error("Failed to fetch initial message count:", error);
      }
    };

    // Only fetch if initialMessageCount is unset or selected contact changes
    if (initialMessageCount === 0 && id) {
      fetchInitialMessageCount();
    }
  }, [id, initialMessageCount, setInitialMessageCount]);

  useEffect(() => {
    // Check for new messages based on current and previous message count
    const checkForNewMessages = async () => {
      try {
        const response = await check_status({ id });
        const newMessageCount = response.data.data.msgCount;
        console.log('previousMessageCountRef.current',previousMessageCountRef.current)
        if (newMessageCount > previousMessageCountRef.current) {
          console.log('New messages detected!');
          const diff=newMessageCount-previousMessageCountRef.current;
          setNewMessageCount(diff); // Set initial message count
          previousMessageCountRef.current = newMessageCount; // Set the ref to this initial value
          setInitialMessageCount(newMessageCount);
          setShowNewMessageAlert(true)
          if (isAtBottom) {
            scrollToBottom(); // Scroll to the bottom of chat
            setChatResult([]); // Clear previous chat messages
            await getchat(id, 1);
            setLoadingMore(true); // Set loading state
          }
          setTimeout(() => setShowNewMessageAlert(false), 3000);
        }
      } catch (error) {
        console.error("Failed to check for new messages:", error);
      }
    };

    // Set up polling for new messages every 15 seconds
    const interval = setInterval(() => checkForNewMessages(), 15000);
    return () => clearInterval(interval);
  }, [id, setInitialMessageCount]);
  

  const handleScroll = () => {
    if (!chatContainerRef.current) return;
    
    // Check if user is at the top
    if (chatContainerRef.current.scrollTop === 0) {
      previousScrollHeightRef.current = chatContainerRef.current.scrollHeight;
      if(chatPage>1){
        getchat(id, chatPage);
      }
      setIsAtTop(true); // Set the flag to true when at the top
    }else{
      setIsAtTop(false); // Set the flag to false when not at the top
    }
  
    // Check if the user is at the bottom
    const isAtBottom =
    chatContainerRef.current.scrollTop + chatContainerRef.current.clientHeight >=
      chatContainerRef.current.scrollHeight - 10;
    setIsAtBottom(isAtBottom);
    setShowScrollButton(!isAtBottom)
  };

  console.log(chatEndRef)

  const scrollToBottom = () => {
    if (!chatEndRef.current || !chatContainerRef.current) return;
    
    const container = chatContainerRef.current;
    const targetPosition = chatEndRef.current.offsetTop;
    const startPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const targetHeight = chatEndRef.current.clientHeight;
    
    // Calculate the exact position to scroll to
    const scrollDistance = targetPosition - startPosition - containerHeight + targetHeight + 32; // adding padding
    
    try {
      container.scrollTo({
        top: container.scrollTop + scrollDistance,
        behavior: 'instant' // Use 'instant' for initial load
      });
    } catch (error) {
      // Fallback for older browsers
      container.scrollTop = container.scrollTop + scrollDistance;
    }
  };



const getchat = async (id,newStartPage) => {
  try {
    const response = await fetch_chat({ id: id,page:newStartPage }); // Pass the current page to the API
    if (response.data.data && response.data.data.chat.length > 0) {
      setChatResult(prevChats => [...response.data.data.chat, ...prevChats]); // Prepend new messages
      setChatpage(newStartPage + 1); // Update the current page
      // setLoadingMore(true);
    } else {
      setLoadingMore(false); // No more data to load
    }

  } catch (error) {
    console.error('Failed to fetch contacts:', error);
  }
};

// useEffect(() => {
//   scrollToBottom();
//   setChatResult([])
//   getchat(id,1);
//   setLoadingMore(true);
  
// }, [id])

useEffect(() => {
  if (!id) return;

  const loadChatAndStatus = async () => {
    try {
      setChatResult([]); // Clear previous chat messages
      setLoadingMore(true); // Set loading state
      await getchat(id, 1); // Load chat messages
      setLoadingMore(false); // Reset loading state
    } catch (error) {
      console.error("Failed to load chat and status:", error);
      setLoadingMore(false);
    }
  };

  loadChatAndStatus();
}, [id]);

// Separate useEffect for scrolling after chat loads
useEffect(() => {
  if (chatResult.length > 0 && !loadingMore) {
    // Small timeout to ensure DOM is updated
    const scrollTimeout = setTimeout(() => {
      scrollToBottom();
    }, 100);
    
    return () => clearTimeout(scrollTimeout);
  }
}, [loadingMore]);

const refreshchat =()=>{
  scrollToBottom();
  setChatResult([])
  setChatpage(1)
  getchat(id,1)
  setLoadingMore(true);
}

// Update scroll position after new messages are loaded
useEffect(() => {

  if (isAtBottom) {
    scrollToBottom();
  } else {
    // Maintain the previous scroll position when loading new data
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
      chatContainerRef.current.scrollHeight - previousScrollHeightRef.current;
    }
  }
}, [chatResult]); // Re-run when messages change

  const handleSendText = async () => {
    if (textMessage.trim()) {
        console.log("Sending text message:", textMessage);
        if (!id) {
          alert("No contact selected to send the message.");
          return;
        }
        setSendText(true);
        
        try {
          const response = await send_message({ message:textMessage,id:id }); // Pass the current page to the API
          console.log(response,'response')
          if(response.data.code==200){
            setChatpage(1);
            setChatResult([])
            getchat(id,1);
            setLoadingMore(true);
            setShowEmojiPicker(false);
            setSendText(false);
          }
          
        } catch (error) {
          console.error('Failed to fetch contacts:', error);
        }
        // Add send logic here
        setTextMessage(''); // Clear text after sending
    } else {
        alert("Message cannot be empty");
    }
};

const onEmojiClick = (emojiObject) => {
  setTextMessage(prevMessage => prevMessage + emojiObject.emoji); // Append emoji to the message
  setShowEmojiPicker(false);
};

useEffect(() => {
  const handleClickOutside = (event) => {
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
      setShowEmojiPicker(false);
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

const fileclear=()=>{
  setIsPopupOpen(false);
  fileInputRef.current.value = '';
}

// Handle image selection and preview display
const handleImageChange = (e) => {
    const file = e.target.files[0];
    let fileType = '';
    let msgType = '';

    const fileMimeType = file && file.type;

    // Determine file type
    if (fileMimeType.startsWith('image/')) {
        fileType = 'image';
        msgType = 'image';
    } else if (fileMimeType.startsWith('audio/')) {
        fileType = 'audio';
        msgType = 'audio';
    } else if (fileMimeType.startsWith('video/')) {
        fileType = 'video';
        msgType = 'video';
    } else if (fileMimeType === 'application/pdf') {
        fileType = 'pdf';
        msgType = 'document';
    } else if (fileMimeType === 'application/vnd.ms-excel' || fileMimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        fileType = 'excel';
        msgType = 'document';
    } else {
        console.log('Unsupported file type');
        return;
    }
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            setFileType(fileType);
            setMsgType(msgType);
            setImageFile(file);
            setImagePreview(reader.result);
            setIsPopupOpen(true); // Open popup for image preview
        };
        reader.readAsDataURL(file);
    }
};

// Send image with accompanying text
const handleSendImageWithText = async() => {
    if (imageFile && imageNote.trim()) {
        console.log("Sending image with text:", { imageFile, imageNote });

        const formData = new FormData();
        formData.append('id', id); // Add contact ID
        formData.append('message', imageNote); // Add the text note
        formData.append('imageFile', imageFile); // Add the image file
        formData.append('fileType', fileType); // Add the image file
        formData.append('msgType', msgType); // Add the image file

        setSendText(true);
        try {
            const response = await send_message(formData, {
              headers: { 'Content-Type': 'multipart/form-data' } // Set header for FormData
            });

            if(response.data.code==200){
              setChatpage(1);
              setChatResult([])
              getchat(id,1);
              setLoadingMore(true);
              setShowEmojiPicker(false);
              setSendText(false);
            }

            if (response.ok) {
                console.log("Image with text sent successfully");
            } else {
                console.error("Failed to send image with text");
            }
        } catch (error) {
            console.error('Error sending image with text:', error);
        }
        // Add send logic here
        setImageFile(null);
        setImagePreview(null);
        setImageNote('');
        setIsPopupOpen(false);
        setFileType('');
        setMsgType('');
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
    } else {
        alert("Both image and message cannot be empty");
    }
};



  if (!selectedContact) {
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
        <p className="tw-text-gray-500">Select a contact to start chatting</p>
      </div>
    );
  }


  // console.log(chatResult,'chatResult')

  const getMessageStatusIcon = (status) => {
    switch (status?.toLowerCase()) {
      case 'queued':
      case 'sent':
        return <BiCheck className="tw-w-4 tw-h-4 tw-text-gray-500" />;
      case 'delivered':
      case 'unread':
        return <BiCheckDouble className="tw-w-4 tw-h-4 tw-text-gray-500" />;
      case 'read':
        return <BiCheckDouble className="tw-w-4 tw-h-4 tw-text-blue-500" />;
      case 'undelivered':
      case 'failed':
        return <i className="bi bi-info-circle tw-w-4 tw-h-4 tw-text-red-500"></i>;
      default:
        return null;
    }
  };


  return (
    <div className="tw-flex tw-flex-col tw-h-[78vh] tw-bg-gray-100">
      {/* Chat header */}
      <div className="tw-flex-none tw-px-4 tw-py-2 tw-bg-white tw-border-b">
        <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-start tw-flex-col">
        <h1 className='tw-font-bold tw-text-xl'>{selectedContact[0]['memberName']}</h1>
        <h2 className="tw-font-bold tw-text-sm tw-text-gray-500">{selectedContact[0]['mobile']}</h2>
        </div>
       

        <div className="tw-flex tw-items-center">
        <span
          onClick={refreshchat}
          className="tw-mr-3 tw-flex tw-items-center tw-cursor-pointer tw-justify-center tw-w-8 tw-h-8 tw-bg-gray-200 tw-rounded-full hover:tw-bg-gray-300"
        >
          <i className="bi bi-arrow-clockwise"></i>
        </span>
          <button onClick={() => {
      resetSelectedContact();
      setCurrentlySelectedContact(null)
       // Assuming this function exists
    }} className="btn btn-primary clear-search-btn">Close chat</button>
        </div>
      </div>
      </div>

      {/* Chat messages */}
      {/* <div className='tw-'> */}
      <div 
      onScroll={handleScroll} 
      ref={chatContainerRef}
      className="tw-flex-1 tw-overflow-y-auto tw-p-4 tw-relative tw-bg-gray-100"
      style={{ 
        minHeight: 0, // Important for proper flex behavior
        scrollbarWidth: 'thin',
        scrollbarColor: '#CBD5E0 #F3F4F6'
      }}
       >
  {chatResult.length > 0 && 
    chatResult.map((message, index) => (
      <React.Fragment>
          <div 
          key={message.id} 
          className={`tw-mb-4 tw-gap-2 tw-flex ${message.senderType === "agent" ? "tw-justify-end" : ""}`} 
          // ref={message.lastElement === true ? chatEndRef : null}
          ref={index === chatResult.length - 1 ? chatEndRef : null}
          >
            
            {/* User message */}
            {message.senderType === "user" && (
              <>
                <div className="tw-w-8 tw-h-8 tw-bg-[#03335b] tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mt-2 tw-flex-shrink-0">
                  {message.sender}
                </div>

                {message.msgType === "image" && (
                  <div className="tw-max-w-[300px]">
                    <div className="tw-bg-white tw-rounded-lg tw-p-1 tw-shadow-sm">
                      <img 
                        src={message.imgUrl} 
                        alt="image" 
                        className="tw-w-full tw-max-h-[280px] tw-object-cover tw-rounded-lg" 
                      />
                    </div>
                  </div>
                )}

                {message.msgType === "audio" && (
                  <div className="tw-bg-white tw-rounded-lg tw-p-2 tw-shadow-sm tw-max-w-[280px]">
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#03335b] tw-flex tw-items-center tw-justify-center">
                        <i className="bi bi-mic-fill tw-text-white"></i>
                      </div>
                      <audio controls className="tw-w-full tw-h-[36px]">
                        <source src={message.imgUrl} type="audio/mpeg" />
                      </audio>
                    </div>
                  </div>
                )}

                {message.msgType === "video" && (
                  <div className="tw-max-w-[300px]">
                    <div className="tw-bg-white tw-rounded-lg tw-p-1 tw-shadow-sm">
                      <video 
                        controls 
                        src={message.imgUrl} 
                        className="tw-w-full tw-max-h-[280px] tw-rounded-lg"
                      />
                    </div>
                  </div>
                )}

                {message.msgType === "document" && (
                  <div className="tw-bg-white tw-rounded-lg tw-shadow-sm tw-max-w-[300px] tw-overflow-hidden">
                    <div className="tw-flex tw-items-center tw-gap-3 tw-p-3 tw-bg-gray-50">
                      <div className="tw-flex-shrink-0">
                        <i className="bi bi-file-earmark-pdf tw-text-2xl tw-text-red-500"></i>
                      </div>
                      <div className="tw-flex-1 tw-min-w-0">
                        <div className="tw-truncate tw-text-sm">Document</div>
                        <div className="tw-text-xs tw-text-gray-500">PDF</div>
                      </div>
                    </div>
                    {message.mimeType === "application/pdf" ? (
                      <iframe
                        src={message.imgUrl}
                        title="PDF Document"
                        className="tw-w-full tw-h-[200px]"
                      />
                    ) : (
                      <a href={message.imgUrl} target="_blank" rel="noopener noreferrer" 
                         className="tw-block tw-p-2 tw-text-blue-600 tw-text-sm tw-text-center hover:tw-bg-gray-50">
                        View Document
                      </a>
                    )}
                  </div>
                )}

                <div className="tw-max-w-[300px]">
                  {!/\.(pdf|csv|jpeg|mp4)$/i.test(message.messageText) && (
                    <div className="tw-bg-white tw-rounded-lg tw-p-2.5 tw-shadow-sm tw-break-words tw-whitespace-pre-wrap">
                      {message.messageText.split('').reduce((result, char, index) => {
                        if (index > 0 && index % 25 === 0) {
                          return result + '\u200B' + char;
                        }
                        return result + char;
                      }, '')}
                    </div>
                  )}
                  <div className="tw-text-xs tw-text-gray-500 tw-mt-1 tw-ml-1"></div>
                </div>
                
              </>
            )}

            {/* Agent message */}
            {message.senderType === "agent" && (
              <>
                <div className={`tw-max-w-[300px] ${message.messageStatus === "undelivered" || message.messageStatus === "failed" ? "tw-flex tw-items-center tw-gap-2" : ""} `}>
                {(message.messageStatus === "undelivered" || message.messageStatus === "failed") ? (
            <div className="tw-relative">
              <div 
                className="tw-cursor-pointer"
                onMouseEnter={() => setActiveTooltip(message.id)}
                onClick={() => setActiveTooltip(message.id)}
                // onMouseLeave={() => setActiveTooltip(null)}
              >
                {getMessageStatusIcon(message.messageStatus)}
              </div>
              
              {activeTooltip === message.id && (
                <div 
                  className="tw-absolute tw-z-[99999] tw-w-64 tw-h-72 tw-overflow-y-scroll tw-right-[19px] tw-bottom-[0px] tw-bg-white tw-rounded-lg tw-border tw-shadow-lg tw-p-4"
                  onMouseEnter={() => setActiveTooltip(message.id)}
                  onMouseLeave={() => setActiveTooltip(null)}
                >
                  <div className="tw-relative">
                    <p className="tw-text-sm tw-font-bold tw-text-red-600 tw-mb-2">
                      ERROR: {message.errorCode}
                    </p>
                    <p className="tw-text-sm tw-text-gray-600 tw-mb-3">
                      {message.description}
                    </p>
                    <div className="tw-flex tw-flex-col tw-gap-2">
                      <span className='tw-text-sm tw-text-wrap'>
                        <a
                          href={`https://www.twilio.com/docs/api/errors/${message.errorCode}`}
                          className="tw-text-blue-500 hover:tw-underline tw-text-sm tw-mr-1"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          View Error Details
                        </a>
                        about Error code <strong>{message.errorCode}</strong>
                      </span>
                      <span className='tw-text-sm tw-break-words'>
                        Possible Solution: {message.solution}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : null}
          <div>

                  {message.msgType === "image" && (
                    <div className="tw-max-w-[300px]">
                      <div className="tw-bg-sky-100 tw-rounded-lg tw-p-1 tw-shadow-sm">
                        <img 
                          src={message.imgUrl} 
                          alt="image" 
                          className="tw-w-full tw-max-h-[280px] tw-object-cover tw-rounded-lg" 
                        />
                      </div>
                    </div>
                  )}

                  {message.msgType === "audio" && (
                    <div className="tw-bg-sky-100 tw-rounded-lg tw-p-2 tw-shadow-sm tw-max-w-[280px]">
                      <div className="tw-flex tw-items-center tw-gap-2">
                        <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#03335b] tw-flex tw-items-center tw-justify-center">
                          <i className="bi bi-mic-fill tw-text-white"></i>
                        </div>
                        <audio controls className="tw-w-full tw-h-[36px]">
                          <source src={message.imgUrl} type="audio/mpeg" />
                        </audio>
                      </div>
                    </div>
                  )}

                  {message.msgType === "video" && (
                    <div className="tw-max-w-[300px]">
                      <div className="tw-bg-sky-100 tw-rounded-lg tw-p-1 tw-shadow-sm">
                        <video 
                          controls 
                          src={message.imgUrl} 
                          className="tw-w-full tw-max-h-[280px] tw-rounded-lg" 
                        />
                      </div>
                    </div>
                  )}

                  {message.msgType === "document" && (
                    <div className="tw-bg-sky-100 tw-rounded-lg tw-shadow-sm tw-max-w-[300px] tw-overflow-hidden">
                      <div className="tw-flex tw-items-center tw-gap-3 tw-p-3 tw-bg-sky-100">
                        <div className="tw-flex-shrink-0">
                          <i className="bi bi-file-earmark-pdf tw-text-2xl tw-text-red-500"></i>
                        </div>
                        <div className="tw-flex-1 tw-min-w-0">
                          <div className="tw-truncate tw-text-sm">Document</div>
                          <div className="tw-text-xs tw-text-gray-500">PDF</div>
                        </div>
                      </div>
                      {message.mimeType === "application/pdf" ? (
                        <iframe
                          src={message.imgUrl}
                          title="PDF Document"
                          className="tw-w-full tw-h-[200px]"
                        />
                      ) : (
                        <a href={message.imgUrl} target="_blank" rel="noopener noreferrer" 
                           className="tw-block tw-p-2 tw-text-blue-600 tw-text-sm tw-text-center hover:tw-bg-sky-200">
                          View Document
                        </a>
                      )}
                    </div>
                  )}

                  {!/\.(pdf|csv|jpeg|mp4)$/i.test(message.messageText) && (
                    <div className="tw-bg-sky-100 tw-rounded-lg tw-p-2.5 tw-shadow-sm tw-break-words tw-whitespace-pre-wrap">
                      {message.messageText}
                    </div>
                  )}

                  <div className="tw-flex tw-items-center tw-justify-end tw-gap-1 tw-mt-1 tw-mr-1">
                    <span className="tw-text-xs tw-text-gray-500"></span>
                    {message.messageStatus === "undelivered" || message.messageStatus === "failed" ? null : (

                    getMessageStatusIcon(message.messageStatus)
                    )}
                  </div>
          </div>
                </div>

                <div className="tw-w-8 tw-h-8 tw-bg-[#03335b] tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mt-2 tw-flex-shrink-0">
                  {message.sender}
                </div>
              </>
            )}
          </div>
        
      </React.Fragment>
    ))
  }
      {showScrollButton && (
    <span
      onClick={scrollToBottom}
      className="tw-sticky tw-bottom-0 tw-right-4 tw-bg-[#03335b] tw-text-white tw-rounded-full tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-shadow-lg hover:tw-bg-[#1279BE] tw-transition-all tw-duration-300 tw-z-50"
      aria-label="Scroll to bottom"
    >
      <i className="bi bi-arrow-down tw-text-lg"></i>
    </span>
  )}
</div>

      {/* </div> */}
      

      {/* Chat input area */}
      <div className="tw-flex-none tw-bg-white tw-border-t">
      <div className="tw-p-2 tw-flex tw-justify-between tw-items-center tw-border-b">
      {/* <div className="tw-flex">
      <button
        className={`tw-px-4 tw-py-2 tw-font-semibold tw-appearance-none tw-transition-all tw-duration-300 ${activeTab === 'reply' ? 'tw-border-b !tw-border-[#03335b]' : ''}`}
        onClick={() => setActiveTab('reply')}
      >
        Reply
      </button>
      <button
        className={`tw-px-4 tw-py-2 tw-font-semibold tw-appearance-none tw-transition-all tw-duration-300 ${activeTab === 'notes' ? 'tw-border-b !tw-border-[#03335b]' : ''}`}
        onClick={() => setActiveTab('notes')}
      >
        Notes
      </button>
    </div> */}
  </div>

  <div className="tw-p-2 tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2">
    {!showkeyboard ? (
      <div className="tw-w-full tw-h-full tw-relative">
        <div className="tw-w-full tw-text-gray-600 tw-text-center tw-bg-gray-100 tw-p-5 tw-rounded">
          The customer has not spoken to you in the last 24 hours.
          <br />
          You can only send pre-approved templates. 
        </div>
        <div className='tw-absolute tw-flex tw-flex-col tw-justify-between tw-top-0 tw-right-0 '>

        <OverlayTrigger placement='top' overlay={<Tooltip>Emoji</Tooltip>}>
          <button
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            className="btn btn-sm tw-border-none"
            disabled
          >
            😊
          </button>

        </OverlayTrigger>
          {showEmojiPicker && (
            <div ref={emojiPickerRef} className="tw-absolute tw-bottom-10 tw-left-0">
            <Picker onEmojiClick={onEmojiClick} />
            </div>
          )}

<OverlayTrigger placement='top' overlay={<Tooltip>Attachment</Tooltip>}>
          <button 
  onClick={() => fileInputRef.current?.click()} 
  className='btn btn-sm tw-border-none'
  disabled
>
<i class="bi bi-paperclip tw-rotate-90"></i>
  <input
    ref={fileInputRef}
    type="file"
    className='tw-hidden'
    accept="image/*,application/pdf"
    onChange={handleImageChange}
    style={{ display: 'none' }}
  />
</button>
</OverlayTrigger>
<OverlayTrigger placement='top' overlay={<Tooltip>Select Template</Tooltip>}>
<button
      className="btn tw-px-4 btn-sm"
      onClick={() => setShowTemplatePopup(true)}
    >
      {/* <span className="tw-mr-2"> */}
      <i class="bi bi-stack"></i>
        {/* </span> */}
      {/* <FiChevronDown className='tw-font-bold' /> */}
    </button>
</OverlayTrigger>
    </div>
      </div>
    ) : (
    <div className='tw-relative tw-w-full'>
      <textarea
        className="tw-border tw-border-gray-300 tw-rounded-2xl tw-w-full tw-pl-2 tw-py-2 tw-pr-10"
        placeholder="Reply here"
        value={textMessage}
        onChange={(e) => setTextMessage(e.target.value)}
        rows={4}
      />

      <div className='tw-absolute tw-flex tw-flex-col tw-justify-between tw-top-0 tw-right-0 tw-h-full'>

        <OverlayTrigger placement='top' overlay={<Tooltip>Emoji</Tooltip>}>
          <button
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            className="btn"
          >
            😊
          </button>

        </OverlayTrigger>
          {showEmojiPicker && (
            <div ref={emojiPickerRef} className="tw-absolute tw-bottom-10 tw-left-0">
            <Picker onEmojiClick={onEmojiClick} />
            </div>
          )}

<OverlayTrigger placement='top' overlay={<Tooltip>Attachment</Tooltip>}>
          <button 
  onClick={() => fileInputRef.current?.click()} 
  className='btn tw-px-4'
>
<i class="bi bi-paperclip tw-rotate-90"></i>
  <input
    ref={fileInputRef}
    type="file"
    className='tw-hidden'
    accept="image/*,application/pdf"
    onChange={handleImageChange}
    style={{ display: 'none' }}
  />
</button>
</OverlayTrigger>
<OverlayTrigger placement='top' overlay={<Tooltip>Select Template</Tooltip>}>
<button
      className="btn tw-px-4 tw-mb-2"
      onClick={() => setShowTemplatePopup(true)}
    >
      {/* <span className="tw-mr-2"> */}
      <i class="bi bi-stack"></i>
        {/* </span> */}
      {/* <FiChevronDown className='tw-font-bold' /> */}
    </button>
</OverlayTrigger>
    </div>
      </div>
    )}
    <div className="tw-flex tw-flex-col tw-w-[150px] tw-gap-2 tw-justify-end">
      <button disabled={sendText} // Disable button when loading
        className="btn btn-primary clear-search-btn"
        onClick={handleSendText} 
      >
        {sendText ? "Sending..." : "Send"}
      </button>
    </div>

    {showNewMessageAlert   &&  (
        <div
          className="tw-bg-blue-500 tw-text-white tw-p-2 tw-rounded tw-cursor-pointer"
          onClick={scrollToBottom}
        >
          {newMessageCount} new {newMessageCount > 1 ? 'messages' : 'message'}
          </div>
      )}
  </div>
</div>

      {showTemplatePopup && (
        <TemplatePopup onClose={() => setShowTemplatePopup(false)} id={id} getchat={getchat}/>
      )}

{isPopupOpen && (
  <div className="tw-fixed tw-inset-0 tw-bg-black/50 tw-flex tw-items-center tw-justify-center tw-z-50 tw-p-4">
    <div className="tw-bg-white tw-rounded-lg tw-w-full tw-max-w-5xl tw-flex tw-flex-col">
      {/* Header */}
      <div className="tw-flex-none tw-sticky tw-top-0 tw-z-10 tw-bg-white tw-px-6 tw-py-4 tw-border-b">
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-relative">
          <h2 className="tw-text-3xl tw-text-center tw-font-semibold tw-text-gray-500" />
          <button
            onClick={() => fileclear()}
            className="tw-absolute tw-top-0 tw-right-0 tw-text-gray-500 hover:tw-text-gray-700 tw-border-0 tw-bg-gray-200 hover:tw-bg-gray-400 tw-rounded-full tw-px-2 tw-py-1"
          >
            ✕
          </button>
        </div>
      </div>

      {/* Content area with controlled height */}
      <div className="tw-flex-1 tw-overflow-y-auto tw-min-h-0 tw-max-h-[calc(90vh-8rem)]">
        <div className="tw-p-4">
          <ImagePreviewPopup 
            imageSrc={imagePreview} 
            note={imageNote}
            onNoteChange={(e) => setImageNote(e.target.value)}
            onClose={() => setIsPopupOpen(false)} 
            onSend={handleSendImageWithText} 
            fileType={fileType}
            sendText={sendText}
          />
        </div>
      </div>
    </div>
  </div>
)}
    </div>
  );
};


const ImagePreviewPopup = ({ imageSrc, fileType, note, onNoteChange, onClose, onSend, sendText }) => {
  return (
    <div className="tw-flex tw-flex-col tw-space-y-4">
      {/* File preview based on file type */}
      <div className="tw-flex tw-justify-center">
        {fileType === 'image' ? (
          <img 
            src={imageSrc} 
            alt="Selected file" 
            className="tw-max-h-[50vh] tw-object-contain tw-w-auto" 
          />
        ) : fileType === 'pdf' ? (
          <embed 
            src={imageSrc} 
            type="application/pdf" 
            className="tw-w-full tw-h-[50vh]" 
          />
        ) : fileType === 'audio' ? (
          <audio controls className="tw-w-full">
            <source src={imageSrc} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        ) : fileType === 'video' ? (
          <video 
            controls 
            className="tw-max-h-[50vh] tw-w-auto"
          >
            <source src={imageSrc} type="video/mp4" />
            Your browser does not support the video element.
          </video>
        ) : fileType === 'excel' ? (
          <div className="tw-text-gray-700 tw-text-center">
            <p>Excel file selected. No preview available.</p>
          </div>
        ) : null}
      </div>

      {/* Text area for adding a note to the file */}
      <textarea
        value={note}
        onChange={onNoteChange}
        placeholder="Add a message to accompany the file"
        className="tw-w-full tw-p-2 tw-border tw-rounded tw-h-24 tw-resize-none"
      />

      <div className="tw-text-center">
        <button
          disabled={sendText}
          onClick={onSend} 
          className="tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-all tw-duration-300 tw-text-white tw-px-8 tw-py-2 tw-rounded"
        >
          {sendText ? "Sending..." : "Confirm & Send"}
        </button>
      </div>
    </div>
  );
};




export default ChatArea;