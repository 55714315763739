import React, { useState, useRef, useCallback } from "react";
import {
  FiSearch,
  FiFilter,
  FiBarChart2,
  FiChevronLeft,
  FiX,
  FiUser,
  FiCheck,
} from "react-icons/fi";
import { useInboxContext } from "./InboxContext";
import { useEffect } from "react";
import {
  member_details,
  update_status,
  member_list,
} from "../../../services/whatsapp/Service";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ContactList = () => {
  const {
    contacts,
    setSelectedContact,
    selectedContacts,
    setSelectedContacts,
    currentPage,
    setCurrentPage,
    hasMore,
    setHasMore,
    loading,
    setFamily,
    setSearchTerm,
    search,
    setStatus,
    setContacts,
    sortOrder,
    setSortOrder,
    status,
    setShowKeyBoard,
    setChatResult,
    setChatpage,
    setLoadingMore,
    setchangeFilterState,
    setLoading,
    unreadCount,
    setUnreadCount,
    currentlySelectedContact,
    setCurrentlySelectedContact,
    setInitialMessageCount
  } = useInboxContext();

  const observer = useRef();

  const fetchContacts = async (status, page, search, sortOrder) => {
    setLoading(true);
    try {
      const response = await member_list({
        status: status,
        page: page,
        search: search,
        sort: sortOrder,
      }); // Pass the current page to the API
      if (response.data.data.list && response.data.data.list.length > 0) {
        setContacts((prevContacts) => [
          ...prevContacts,
          ...response.data.data.list,
        ]); // Append new contacts
        setUnreadCount(response.data.data.unreadcount);
        //setChatpage(page + 1); // Update the current page
      } else {
        setHasMore(false); // No more data to load
      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchContacts(status, currentPage, search, sortOrder);
  }, [currentPage, search]);

  const dropdownFilter = (e) => {
    console.log("e.target.value", e.target.value);
    const selectedValue = e.target.value; // Capture selected value
    setStatus(selectedValue); // Update status in context
    setContacts([]); // Clear previous contacts
    setHasMore(true);
    //alert(currentPage)
    if (currentPage == 1) {
      fetchContacts(selectedValue, 1);
    } else {
      setCurrentPage(1);
    }
  };

  // Callback to handle loading the next page when reaching the bottom of the list
  const lastContactElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((currentPage) => currentPage + 1); // Load next pag
          // fetchContacts(status, currentPage);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const [activeMode, setActiveMode] = useState(null); // 'search', 'filter', or 'selection'
  

  const toggleMode = (mode) => {
    setActiveMode(activeMode === mode ? null : mode);
  };
  const handleReset = () => {
    setStatus("all"); // Reset chat status
    setSortOrder("Newest"); // Reset sort order
    //setCurrentPage(1); // Call API on Enter
  };

  const filterReset = () => {
    // alert("here");
    setActiveMode(null);
    setContacts([]);
    setHasMore(true);
    if (currentPage == 1) {
      fetchContacts("all", 1, "", "");
    } else {
      setCurrentPage(1);
    }
    setSearchTerm("");
  };

  const handleApply = () => {
    setActiveMode(null); // Close filter view
    setHasMore(true);
    setContacts([]);
    if (currentPage == 1) {
      fetchContacts(status, 1, "", sortOrder);
    } else {
      setCurrentPage(1);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearchTerm(event.target.value); // Set search term
      setContacts([]);
      setCurrentPage(1); // Call API on Enter
      setHasMore(true);
    }
  };

  const toggleSelection = (id) => {
    setSelectedContacts((prev) =>
      prev.includes(id)
        ? prev.filter((contactId) => contactId !== id)
        : [...prev, id]
    );
  };

  const refreshmembers = () => {
    setContacts([]);
    setCurrentPage(1); // Call API on Enter
    setHasMore(true);
    setActiveMode(null); // Close filter view
    setStatus("all"); // Reset chat status
    fetchContacts(status, 1, "", "");
  };
//const isDisabled = currentlySelectedContact === selectedContact[0]?.id;


  const handleContactClick = async (contact) => {
    if (!activeMode || activeMode !== "selection") {
      console.log("contact", contact);
      try {
        const response = await member_details({ id: contact.id }); // Pass the current page to the API
        if (response.data.data.member.length > 0) {
          setSelectedContact(response.data.data.member);
          setCurrentlySelectedContact(contact.id);
          setFamily(response.data.data.family);
          setShowKeyBoard(response.data.data.showKeyboard);
          console.log(response.data.data.showKeyboard,'ss')
          setChatpage(1);
          setLoadingMore(true);
          setChatResult([]);
          update_status({ id: contact.id });
          
        }
      } catch (error) {
        console.error("Failed to fetch contacts:", error);
      }
    }
  };

  return (
    <div className="tw-h-full tw-flex tw-flex-col">
      {/* <Header 
        activeMode={activeMode}
        toggleMode={toggleMode}
        selectedCount={selectedContacts.length}
      /> */}
      <div className="tw-h-full tw-flex tw-flex-col">
        <Header
          activeMode={activeMode}
          toggleMode={toggleMode}
          selectedCount={selectedContacts.length}
          setchangeFilterState={setchangeFilterState}
          fetchContacts={fetchContacts}
          currentPage={currentPage}
          dropdownFilter={dropdownFilter}
          unreadCount={unreadCount}
          refreshmembers={refreshmembers}
        />

        {/* Search and Filter View */}
        <div className="tw-flex-grow tw-overflow-y-auto">
          {activeMode === "search" && (
            // <div className="tw-relative tw-w-full tw-bg-white">
              <SearchView
                setActiveMode={setActiveMode}
                setSearchTerm={setSearchTerm}
                handleKeyDown={handleKeyDown}
                fetchContacts={fetchContacts}
                search={search}
                setContacts={setContacts}
                setHasMore={setHasMore}
                setCurrentPage={setCurrentPage}
                searchJSX={<div className="tw-mt-2 tw-relative tw-overflow-y-auto">
                  <ContactItems
                    contacts={contacts}
                    selectionMode={activeMode === "selection"}
                    selectedContacts={selectedContacts}
                    toggleSelection={toggleSelection}
                    handleContactClick={handleContactClick}
                    currentlySelectedContact={currentlySelectedContact}
                    lastContactElementRef={lastContactElementRef}
                  />
                </div>}
              />
            // </div>
          )}

          {activeMode === "filter" && (
            <FilterView
              setActiveMode={setActiveMode}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              setStatus={setStatus}
              status={status}
              handleReset={handleReset}
              handleApply={handleApply}
              setHasMore={setHasMore}
              setCurrentPage={setCurrentPage}
              setContacts={setContacts}
              setSearchTerm={setSearchTerm}
              filterReset={filterReset}
            />
          )}

          {/* Contacts List - Separate container for results */}
          <div className="tw-mt-2 tw-relative tw-overflow-y-auto">
            <ContactItems
              contacts={contacts}
              selectionMode={activeMode === "selection"}
              selectedContacts={selectedContacts}
              toggleSelection={toggleSelection}
              handleContactClick={handleContactClick}
              currentlySelectedContact={currentlySelectedContact}
              lastContactElementRef={lastContactElementRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = ({
  activeMode,
  toggleMode,
  selectedCount,
  setchangeFilterState,
  fetchContacts,
  currentPage,
  dropdownFilter,
  unreadCount,
  refreshmembers,
}) => {
  const {
    setStatus,
    setCurrentPage,
    setContacts,
    status,
    setHasMore,
  } = useInboxContext(); // Include status

  if (activeMode === "selection") {
    return (
      <div className="tw-flex tw-justify-between tw-items-center tw-p-4 tw-border-b tw-bg-white tw-rounded-">
        <div className="tw-flex tw-items-center">
          <button onClick={() => toggleMode(null)} className="tw-mr-2">
            <FiChevronLeft />
          </button>
          <span>{selectedCount} selected</span>
        </div>
        <div>
          <button className="tw-mr-2">
            <FiUser />
          </button>
          <button>
            <FiCheck />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-p-2 tw-border-b tw-bg-white">
  <div className="tw-flex tw-items-center tw-gap-3 tw-flex-1 tw-max-w-[600px]">
    <div className="tw-flex-1 tw-max-w-[200px]">
      <select
        onChange={(e) => dropdownFilter(e)}
        value={status}
        className="form-select tw-w-full tw-pr-8 tw-py-2 tw-rounded-xl tw-border"
      >
        <option value="all">All</option>
        <option value="read">Read</option>
        <option value="unread">Unread</option>
      </select>
    </div>

    {unreadCount>=0 && (
      <div className="tw-flex-shrink-0">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              Unread Chats
            </Tooltip>
          }>
        <span className="tw-inline-flex tw-items-center tw-justify-center tw-min-w-[40px] tw-h-[38px] tw-bg-[#03335b] tw-text-white tw-rounded-xl tw-px-3">
          <span className="tw-text-base">{unreadCount}</span>
        </span>
        </OverlayTrigger>
      </div>
    )}

    <span
      onClick={refreshmembers}
      className="tw-flex-shrink-0 tw-inline-flex tw-cursor-pointer tw-items-center tw-justify-center tw-h-[38px] tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-colors tw-duration-200 tw-text-white tw-rounded-xl tw-px-3"
    >
      <i className="bi bi-arrow-clockwise tw-text-lg"></i>
    </span>
  </div>

  <div className="tw-flex tw-items-center tw-gap-2 tw-ml-2">
    <span 
      onClick={() => toggleMode("search")} 
      className="tw-inline-flex tw-items-center tw-cursor-pointer tw-justify-center tw-h-[38px] tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-colors tw-duration-200 tw-text-white tw-rounded-xl tw-px-3"
    >
      <FiSearch size={18} />
    </span>
    
    <span 
      onClick={() => toggleMode("filter")} 
      className="tw-inline-flex tw-items-center tw-cursor-pointer tw-justify-center tw-h-[38px] tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-colors tw-duration-200 tw-text-white tw-rounded-xl tw-px-3"
    >
      <FiFilter size={18} />
    </span>
  </div>
</div>
  );
};

const SearchView = ({
  setActiveMode,
  handleKeyDown,
  searchTerm,
  setSearchTerm,
  fetchContacts,
  search,
  setContacts,
  setHasMore,
  setCurrentPage,
  searchJSX,
}) => (
  <div style={{backgroundColor: 'white !important'}} className="tw-absolute tw-inset-0 tw-bg-white tw-z-10">
    <div className="tw-p-4">
      <div className="tw-flex tw-items-center tw-mb-4">
        <span
          onClick={() => {
            setActiveMode(null);
            setContacts([]);
            setHasMore(true);
            setCurrentPage(1);
            setSearchTerm("");
          }}
          className="tw-mr-2 tw-mb-1 tw-cursor-pointer tw-text-gray-500 tw-text-lg"
        >
          <FiChevronLeft />
        </span>
        <h4 className="tw-font-bold tw-text-gray-500">Search Inbox</h4>
      </div>
      <div className="tw-relative">
        <input
          type="text"
          placeholder="Search for your message or users"
          className="form-control input-field-ui tw-pl-9"
          value={searchTerm} // Bind to the local search term state
          //onChange={(e) => setSearchTerm(e.target.value)} // Update local state on change
          onKeyDown={handleKeyDown} // Handle key down events
        />
        <FiSearch
          className="tw-absolute tw-left-3 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-text-gray-400"
          size={20}
        />
      </div>
    </div>
    {/* {!search && (
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-8">
        <div className="tw-bg-gray-200 tw-rounded-full tw-p-4">
          <FiSearch size={40} className="tw-text-gray-400" />
        </div>
        <p className="tw-text-center tw-mt-4 tw-text-gray-500">
          Search for messages or users
          <br />
          with the help of their name or contact number.
        </p>
      </div>
    )} */}
    {searchJSX}
  </div>
);

const FilterView = ({
  setActiveMode,
  setStatus,
  status,
  setContacts,
  setHasMore,
  setCurrentPage,
  setSearchTerm,
  sortOrder,
  setSortOrder,
  handleApply,
  handleReset,
  filterReset,
}) => (
  <div
    className="tw-absolute tw-inset-0 tw-bg-white tw-z-10 tw-overflow-y-auto"
  >
    <div className="tw-p-4">
      <div className="tw-flex tw-justify-start tw-items-center tw-mb-4">
      <span
          onClick={filterReset}
          className="tw-mr-2 tw-mb-1 tw-cursor-pointer tw-text-gray-500 tw-text-lg"
        >
          <FiChevronLeft className="tw-mb-1" />
        </span>
      <h4 className="tw-font-bold tw-text-gray-500">Select filters</h4>
      </div>
      <div className="tw-mb-4">
        <p className="tw-font-semibold tw-mb-2 tw-text-gray-500">Chat Status</p>
        <select
          className="form-select input-field-ui"
          value={status} // Bind chatStatus prop
          onChange={(e) => setStatus(e.target.value)} // Update state on change
        >
          <option value="">All</option>
          <option value="Read">Read</option>
          <option value="Unread">Unread</option>
        </select>
      </div>
      <div className="tw-mb-4">
      <p className="tw-font-semibold tw-mb-2 tw-text-gray-500">Sort</p>
        <select
          className="form-select input-field-ui"
          value={sortOrder} // Bind sortOrder prop
          onChange={(e) => setSortOrder(e.target.value)} // Update state on change
        >
          <option value="Newest">Newest</option>
          <option value="Oldest">Oldest</option>
        </select>
      </div>
      <div className="tw-flex tw-justify-between">
        <button onClick={handleReset} className="btn btn-outline-dark">
          Reset
        </button>
        <button
          onClick={handleApply}
          className="btn btn-primary clear-search-btn"
        >
          Apply Filters
        </button>
      </div>
    </div>
  </div>
);

const ContactItems = ({
  contacts,
  selectionMode,
  selectedContacts,
  toggleSelection,
  handleContactClick,
  currentlySelectedContact,
  lastContactElementRef,
}) => {

  const truncateText = (text) => {
    const words = text.split(' ');
    if (words.length > 8) {
      return words.slice(0, 8).join(' ') + '...';
    }
    return text;
  };

  return (
  <div className="tw-overflow-y-auto">
    {contacts.map((contact, index) => (
      <div 
        key={contact.id}
        ref={index === contacts.length - 1 ? lastContactElementRef : null} // Attach ref to last item
        className={`tw-flex border-bottom tw-transition-all tw-duration-300 tw-items-center tw-p-4 tw-cursor-pointer 
          ${selectionMode && selectedContacts.includes(contact.mobile) ? "tw-bg-blue-300" : ""}
          ${!selectionMode && currentlySelectedContact === contact.mobile ? "tw-bg-blue-100" : ""}
          ${currentlySelectedContact === contact.id ? 'tw-opacity-50 tw-pointer-events-none' : ''} // Add styles for disabled
        `}
        onClick={() => {
            handleContactClick(contact); // Call the handler only if the contact is different
        }}
      >
        <div className="tw-flex-grow">
          <div className="tw-flex tw-justify-between">
            <span className="tw-font-semibold">{contact.memberName}</span>
            <span className="tw-text-sm tw-text-gray-500">
              {contact.messageTime}
            </span>
          </div>
          <div className="tw-flex tw-items-center tw-justify-between">
            {/* <span className="tw-text-green-500 tw-mr-1">●</span> */}
            <span className="tw-text-sm tw-text-gray-500">
            {truncateText(contact.messageText)}
            </span>
            {contact.unreadCount > 0 && (

<span className="tw-text-xs tw-text-white tw-px-2 tw-py-1 tw-bg-[#03335b] tw-rounded-full">{contact.unreadCount}</span>
)}
          </div>
        </div>
        {/* <hr></hr> */}
      </div>
    ))}
  </div>
);
}

export default ContactList;
