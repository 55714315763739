import React from 'react'
import ContactList from './ContactList';
import ChatArea from './ChatArea';
import UserDetails from './UserDetails';
import { InboxProvider } from './InboxContext';

const Inbox = () => {
  return (
    <InboxProvider>
      <div className="tw-flex tw-h-[80vh] tw-p-1 tw-m-3  tw-overflow-y-auto tw-bg-white tw-rounded-3xl tw-transition-all tw-duration-300">
        {/* Left Sidebar - Contact List */}
        <div className="tw-w-1/4 tw-border-r tw-border-gray-300 tw-relative tw-z-0">
          <ContactList />
        </div>

        <div className='vr'></div>
        
        {/* Main Chat Area */}
        <div className="tw-w-1/2 tw-flex tw-flex-col">
          <ChatArea/>
        </div>

        <div className='vr'></div>

        
        {/* Right Sidebar - User Details */}
        <div className="tw-w-1/4 tw-border-l tw-border-gray-300 tw-overflow-y-auto" >
          <UserDetails />
        </div>
      </div>
    </InboxProvider>
  )
}

export default Inbox