import common_axios from "./commonAxios";

let baseURL = common_axios.defaults.baseURL;

// fetch admin userdata 
export const member_list = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/member_list', params)
    return dataPass;
};

export const member_details = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/member_details', params)
    return dataPass;
};
export const send_message = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/send_message', params)
    return dataPass;
};
export const fetch_chat = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/fetch_chat', params)
    return dataPass;
};

export const update_status = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/update_status', params)
    return dataPass;
};

export const whatsapp_list = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/whatsapp_list', params)
    return dataPass;
};
export const sendTemplateApi = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/whatsapp_send', params)
    return dataPass;
};

export const check_status = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/check_status', params)
    return dataPass;
};

